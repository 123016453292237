import { Heading } from "@chakra-ui/react"
import React from "react"
import Layout from "../components/layout/layout"
import SearchLayout from "../components/maps/searchLayoutBase"
import { KioskMessagesBanner } from "./KioskMessagesBanner"
const AreaTemplate = ({ pageContext, location }: any) => {
  const {
    values,
    name,
    description,
    metaTitle,
    search,
    messages,
    facet,
    seeAlso,
    waitForUserLocation
  } = pageContext



  const facetTransform = { products: facet }
  return (
    <Layout
      title={
        metaTitle ??
        `Stalletjes langs de weg in ${name} - Lokale Kraampjes met groente, fruit, melk enz`
      }
      description={description}
      location={location}
      pageContext={pageContext}
    >
      <SearchLayout
        pageContext={pageContext}
        kiosks={values}
        prefixSearch={search}
        doNotSearchOnInit
        infoBelowMap
        facet={facetTransform}
        waitForUserLocation={false}
      >
        <KioskMessagesBanner messages={messages ?? []} />
        <div style={{ margin: "0 1rem 1rem 1rem" }}>
          <Heading
            as="h1"
            size="xl"
            style={{ marginBottom: "1rem", fontWeight: 900 }}
          >
            {name}
          </Heading>
          <div>
            <div
              dangerouslySetInnerHTML={{ __html: description }}
              style={{ color: "#666" }}
              className="text"
            ></div>
            {(seeAlso ?? []).length > 0 && (
              <div style={{ marginTop: "1rem" }}>
                Zie ook:{" "}
                {seeAlso.map((x: any, i: number) => (
                  <React.Fragment key={x.url}>
                    {i !== 0 && <>, </>}
                    <a href={"/" + x.url}>{x.key}</a>
                  </React.Fragment>
                ))}{" "}
              </div>
            )}
          </div>
        </div>
      </SearchLayout>
    </Layout>
  )
}

export default AreaTemplate
