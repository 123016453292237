import { Heading } from "@chakra-ui/react"
import { Link } from "gatsby"
import React from "react"
import KioskPicture from "../components/kioskPicture"
import { KioskMessage } from "../interfaces/kiosk"
import { stringToDate } from "../utility/date"
import { firstWordsFallback } from "../utility/string"

export function KioskMessagesBanner({
  messages,
}: {
  messages: KioskMessage[]
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: "wrap",
        marginTop: "3rem",
      }}
    >
      {messages?.map(x => (
        <div
          key={x.RowKey}
          style={{
            maxWidth: "395px",
            minWidth: "var(--minWidthNew)",
            height: "100%",
          }}
        >
          <Link to={x.kioskSlug ?? ""}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "0.3em",
              }}
            >
              <Heading size="xs">{x.kioskName}</Heading>
              <span
                style={{
                  fontSize: "70%",
                  color: "#1e1e20",
                }}
              >
                {stringToDate(x.publishDate)}
              </span>
            </div>
            <div
              className="kioskContainer"
              style={{
                backgroundColor: "#F1F8F0",
                fontSize: "0.875em",
                marginBottom: "20px",
                border: "none",
                overflow: "none",
                minHeight: "6rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: "1rem",
                }}
              >
                {x.imageUrl && (
                  <div style={{ width: "5rem" }}>
                    <KioskPicture picture={x.imageUrl} size={3} />
                  </div>
                )}

                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Heading size="xs">{x.title}</Heading>
                  </div>
                  <span>{firstWordsFallback(x.message, 100)}...</span>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}{" "}
    </div>
  )
}
